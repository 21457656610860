import * as React from "react"

import Container from "@components/elements/container"
import TextBlock, { TextBlockProps } from "@components/elements/textBlock"

import styles from "./textSection.module.scss"

const TextSection = ({
  tagline,
  title,
  subtitle,
  text,
  cta,
}: TextBlockProps) => (
  <section className={styles.textSection}>
    <Container className={styles.container} isNarrow>
      <TextBlock
        className={styles.textContent}
        tagline={tagline}
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
      />
    </Container>
  </section>
)

export default TextSection
