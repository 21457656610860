import * as React from "react"
import { createPortal } from "react-dom"
import cx from "classnames"

import CloseIcon from "@images/close-icon.inline.svg"

import styles from "./lightbox.module.scss"

interface LightboxProps {
  onClose: () => void
  className?: string
  containerClassName?: string
  children?: React.ReactNode
  showCloseButton?: boolean
}

const Lightbox = React.forwardRef(
  (
    {
      onClose,
      className,
      containerClassName,
      showCloseButton = true,
      children,
    },
    ref
  ) => {
    return createPortal(
      <div className={cx(styles.lightbox, className)} ref={ref}>
        <div
          className={styles.overlay}
          onClick={onClose}
          role="button"
          aria-label="Close modal"
        ></div>
        <div className={cx(styles.container, containerClassName)}>
          {showCloseButton && <CloseButton onClick={onClose} />}
          {children}
        </div>
      </div>,
      document.body
    )
  }
)

interface CloseButtonProps {
  onClick: () => void
  className?: string
}

export const CloseButton = ({ onClick, className }: CloseButtonProps) => (
  <button
    className={cx(styles.buttonClose, className)}
    onClick={onClick}
    type="button"
    aria-label="Close modal"
  >
    <CloseIcon className={styles.closeIcon} />
  </button>
)

export default Lightbox
