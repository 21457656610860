import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Lightbox from "@components/elements/lightbox"

import ArrowRight from "@images/arrowRight.inline.svg"

import styles from "./personLightbox.module.scss"

const Persons = React.forwardRef(({ person, hide }, ref) => {
  return (
    <Lightbox
      ref={ref}
      onClose={hide}
      className={styles.lightbox}
      containerClassName={styles.lightboxContainer}
    >
      {person && (
        <div className={styles.lightboxPerson}>
          <GatsbyImage
            className={styles.lightboxImage}
            image={getImage(person?.image?.imageData)}
            alt={`${person.name} portrait`}
          />
          {person.name && (
            <div className={styles.lightboxName}>{person.name}</div>
          )}
          {person.position && (
            <div className={styles.lightboxPosition}>{person.position}</div>
          )}
          {person.text && (
            <div className={styles.lightboxText}>{person.text}</div>
          )}
          {person.contact && (
            <a className={styles.lightboxCta} href={`mailto:${person.contact}`}>
              {person.contact}
              <ArrowRight />
            </a>
          )}
        </div>
      )}
    </Lightbox>
  )
})

export default Persons
