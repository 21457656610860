import * as React from "react"
import { useState, useCallback, useRef } from "react"
import { graphql } from "gatsby"
import { CSSTransition } from "react-transition-group"

import Layout from "@components/layout/layout"
import TextSection from "@components/textSection"
import GlowBackground from "@components/elements/glowBackground"
import PersonLightbox from "@components/persons/personLightbox"
import PersonsFilter from "@components/persons/personsFilter"
import Persons, { PersonType } from "@components/persons/persons"

const ALL_CATEGORY = "All"

const About = ({ data }: any) => {
  const { meta_title, meta_description, meta_image, intro, body } =
    data.prismicAbout.data
  const items = body.filter(item => {
    return item.primary?.show
  })
  const [activeCategory, setActiveCategory] = useState(ALL_CATEGORY)
  const filterCategories = [
    ALL_CATEGORY,
    ...new Set(items.map(item => item.primary?.title)),
  ]

  const [activePerson, setActivePerson] = useState<PersonType | null>(null)
  const [isLightboxOpen, setIsLightboxOpen] = useState<Boolean>(false)
  const show = useCallback(item => {
    setActivePerson(item)
    setIsLightboxOpen(true)
  }, [])
  const hide = useCallback(() => setIsLightboxOpen(false), [])
  const lightboxRef = useRef(null)

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
    >
      <GlowBackground>
        <TextSection tagline="About" subtitle={intro.text} />
        <PersonsFilter
          filterCategories={filterCategories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
        {items.map(item => {
          const itemTitle = item.primary?.title

          return (
            (activeCategory === ALL_CATEGORY ||
              itemTitle === activeCategory) && (
              <Persons
                key={item.id}
                title={activeCategory === ALL_CATEGORY && item.primary?.title}
                items={item.items.reduce((result, { person }) => {
                  person?.document &&
                    result.push({
                      id: person.document.id,
                      name: person.document.data.full_name.text,
                      position: person.document.data.function,
                      text: person.document.data.bio.text,
                      contact: person.document.data.contact.text,
                      image: person.document.data.image,
                    })

                  return result
                }, [])}
                activePerson={activePerson}
                show={show}
                hide={hide}
              />
            )
          )
        })}
      </GlowBackground>
      <CSSTransition
        in={isLightboxOpen}
        lightboxRef={lightboxRef}
        timeout={400}
        classNames="show"
        unmountOnExit
        onExited={() => setActivePerson(null)}
      >
        <PersonLightbox person={activePerson} hide={hide} ref={lightboxRef} />
      </CSSTransition>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    prismicAbout {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        intro {
          text
        }
        body {
          ... on PrismicAboutDataBodyListOfPersons {
            primary {
              title
              show
            }
            id
            items {
              person {
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      image {
                        imageData: gatsbyImageData(
                          placeholder: BLURRED
                          aspectRatio: 1
                        )
                      }
                      full_name {
                        text
                      }
                      function
                      bio {
                        text
                      }
                      contact {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
