import * as React from "react"
import cx from "classnames"

import Container from "@components/elements/container"
import Select from "@components/elements/select"

import styles from "./persons.module.scss"

const PersonsFilter = ({
  filterCategories,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <Container className={cx(styles.container, styles.filterContainer)}>
      <Select
        items={filterCategories}
        activeItem={activeCategory}
        setActiveItem={setActiveCategory}
      />
    </Container>
  )
}

export default PersonsFilter
