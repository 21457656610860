import * as React from "react"
import { useRef } from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import Container from "@components/elements/container"

import { ConditionalWrapper } from "@lib/utils"

import PlusIcon from "@images/plus.inline.svg"

import styles from "./persons.module.scss"

export interface PersonType {
  id: string
  name: string
  position?: string
  text?: string
  imageData: ImageDataLike
}

interface PersonsProps {
  title?: string
  text?: string
  contact?: string
  items: Array<PersonType>
  activePerson: PersonType
  show: () => void
  hide: () => void
}

const Persons = ({
  title,
  items = [],
  activePerson,
  show,
  hide,
}: PersonsProps) => {
  if (!items.length) {
    return null
  }

  const ref = useRef(null)
  return (
    <section className={styles.persons} ref={ref}>
      <Container className={styles.container}>
        {title && <h2 className={styles.personsTitle}>{title}</h2>}

        <ul className={styles.personList}>
          {items.map(item => {
            const image = getImage(item.image.imageData)

            return (
              <li className={styles.person} key={item.id}>
                <ConditionalWrapper
                  condition={item.text}
                  wrapper={wrapChildren => {
                    return (
                      <button
                        className={styles.personButtonOpen}
                        type="button"
                        onClick={() => show(item)}
                        aria-label={`Open ${item.name} details`}
                      >
                        <div className={styles.personButtonOpenOverlay}></div>
                        <PlusIcon className={styles.personButtonOpenIcon} />
                        {wrapChildren}
                      </button>
                    )
                  }}
                >
                  <div className={styles.personImage}>
                    <GatsbyImage
                      image={image}
                      alt={`Headshot of ${item.name}`}
                    />
                  </div>
                </ConditionalWrapper>
              </li>
            )
          })}
        </ul>
      </Container>
    </section>
  )
}

export default Persons
