import * as React from "react"
import cx from "classnames"
import parse from "html-react-parser"

import Wysiwyg from "@components/elements/wysiwyg"
import Cta from "@components/elements/cta"

import styles from "./textBlock.module.scss"

export interface TextBlockProps {
  tagline?: string
  title?: string
  subtitle?: string
  text?: string
  cta?: object
  className?: string
}

const TextBlock = ({
  tagline,
  title,
  subtitle,
  text,
  cta,
  className,
}: TextBlockProps) => {
  return (
    <div className={cx(styles.textBlock, className)}>
      {tagline && <div className={styles.tagline}>{tagline}</div>}
      {title && (
        <h2 className={styles.title}>{parse(title, { trim: true })}</h2>
      )}
      {subtitle && (
        <h3 className={styles.subtitle}>{parse(subtitle, { trim: true })}</h3>
      )}
      {text && <Wysiwyg className={styles.text} contentHtml={text} />}
      <Cta {...cta} className={styles.buttonCta} />
    </div>
  )
}

export default TextBlock
